section{
    min-height: 800px;
}

.landing{
    display: flex;
    justify-content: space-between;
    padding-left: 10vw;
    padding-right: 15vw;
    
}
.heading{
    /* position: absolute; */
    padding-top: 20vh;
    display: inline-block;
    flex-direction: column;
    justify-content: center;
    padding-right: 3vh;
}
.heading h1{
    background-image: url(../img/clouds.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 60% 50%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    padding-bottom: 3vh;

}
.heading p{
    width: 40vw;
    padding-bottom: 2vh;
}

/*  */
.conntact-button{
  border-radius: 10%;
  padding: 15px 20px;
  background-color: var(--button-color);
  color: var(--primary-text-color);
  font-weight: bold;
  text-decoration: none;
  /* uper case */
  text-transform: uppercase;
  font-size: 1.25rem;

}

.landing-image{
  
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 20vh;
    width: 60vw;
    height: 500px;  
    border-radius: 10px;

}


svg{
    fill: #142d4c
}

.first-baner{
  margin-top: 20vh;
  margin-bottom: 10vh;
}



/* napravi animaciju da se landing heading pojavi sa 1 sekundom zakasnjena od trenutka ucitavanja stranice */


.landing .heading h1{
    opacity: 0; /* Postavljamo početnu transparentnost na 0 */
    animation: fadeIn 2s ease forwards; /* Dodajemo animaciju */

  }

.landing .heading p {
    opacity: 0;
    animation: sladeInLeft 2s ease forwards; /* Dodajemo animaciju */
    animation-delay: 0.5s;

  }

  .landing .heading .line{
    opacity: 0;
    animation: sladeInLeft 2s ease forwards; /* Dodajemo animaciju */
    animation-delay: 0.8s;

  }

  .landing .heading .button-container{
    padding-top: 2vh;
    opacity: 0;
    animation: sladeInLeft 2s ease forwards; /* Dodajemo animaciju */
    animation-delay: 1s;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


@keyframes sladeInLeft {
    from {
    transform: translateX(-5%);
    opacity: 0;
    }
    to {
    transform: translateX(0);
    opacity: 1;
    }
    }

/* Napraviti da se slika zumira pri ucitavanju stranice */


.landing-image img {
  animation: slideInRight 1s ease forwards;
}
/* define colors */
:root {
    /* Redefinisana paleta sa svetlijom plavom */
    --primary-color: #1E3D59;          /* Tamno plava, glavna boja */
    --secondary-color: #0A1721;        /* Veoma tamna plavo-crna za pozadinu */
    --third-color: #11202E;            /* Malo svetlija tamno plava */
    --primary-text-color: #7AA5D2;     /* Svetlija, nežnija plava za naglašeni tekst */
    --secondary-text-color: #ffffff;    /* Bela za glavni tekst */
    --third-text-color: #B8D3E8;       /* Vrlo svetla plava za sekundarni tekst */
    --hover-color: #5B9BD5;            /* Srednja plava za hover */
    --button-color: #1E3D59;           /* Ista kao primary */
    --accent-color: #15293D;           /* Tamnija plava za akcente */
    --background-gradient-start: #0A1721;
    --background-gradient-end: #11202E;
}

/* Reset i osnovni stilovi */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Cormorant Garamond', serif;  /* Elegantniji font za naslove */
    background-color: var(--secondary-color);
    scroll-behavior: smooth;
    line-height: 1.6;
}

/* Typography */
p {
    font-family: 'Montserrat', sans-serif;
    color: var(--third-text-color);
    line-height: 1.8;
    font-size: 1.1rem;
    font-weight: 300;
    letter-spacing: 0.3px;
}

h1, h2 {
    font-family: 'Cormorant Garamond', serif;
    color: var(--primary-text-color);
    letter-spacing: 1.2px;
}

h1 {
    font-size: 4.5rem;
    font-weight: 700;
    margin: 2vh 0;
}

h2 {
    font-size: 2.8rem;
    font-weight: 600;
    margin: 2vh 0;
}

/* Section styling */
section {
    padding: 8vh 8vw;
    background: linear-gradient(
        0deg, 
        var(--background-gradient-start) 0%, 
        var(--background-gradient-end) 100%
    );
}

/* Line elements */
.line {
    width: 120px;
    height: 3px;
    background: linear-gradient(90deg, 
        var(--accent-color) 0%, 
        var(--primary-color) 50%, 
        var(--primary-text-color) 100%
    );
    margin: 2vh 0;
    transition: width 0.3s ease;
}

.line-80 {
    width: 180px;
    height: 3px;
    background: linear-gradient(90deg, 
        var(--accent-color) 0%, 
        var(--primary-color) 50%, 
        var(--primary-text-color) 100%
    );
    margin: 2vh 0;
}

/* Landing page specific */
.landing-h1 {
    font-size: 4.5rem;    
    letter-spacing: 2px;
    color: var(--primary-text-color);
    text-transform: uppercase;
    line-height: 1.2;
    font-weight: 700;
    animation: fadeIn 1s ease forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Footer copyright */
#rights {
    text-align: center;
    padding: 3vh 0;
    font-size: 0.9rem;
    color: var(--third-text-color);
    font-family: 'Montserrat', sans-serif;
}

/* Image styling */
.box-shadow {
    box-shadow: 0 20px 40px rgba(0,0,0,0.3);
    transition: transform 0.3s ease;
}

.box-shadow:hover {
    box-shadow: 0 20px 40px rgba(30, 61, 89, 0.2);
    transform: translateY(-5px);
}

/* Responsive font sizes */
@media (max-width: 768px) {
    .landing-h1 {
        font-size: 3rem;
    }
    
    h1 {
        font-size: 3.5rem;
    }
    
    h2 {
        font-size: 2.2rem;
    }
    
    p {
        font-size: 1rem;
    }
}

.hover-color{
    color: var(--hover-color);
}

html {
    scroll-behavior: smooth;
}

h1, h2, p {
    margin: 2vh 0;
}

h2{
    font-size: 3rem;
    text-transform: uppercase;
}

.line{
    width: 30vw;
    height: 6px;
    background-color: var(--button-color);
    margin-top: 1vh;
    margin-bottom: 1vh;

}

.line-80{
    width: 50vw;
    height: 6px;
    background-color: var(--button-color);
    margin-top: 1vh;
    margin-bottom: 1vh;

}

.baner{
    background-attachment: fixed !important;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    /* margin: 20vh 0; */
;
}

.baner{
    min-height: 60vh;
}

.baner h1{
    text-align: center;
    font-size: 4rem;
    /* letter-spacing: 5px; */
    color : var(--third-text-color);
    text-transform: uppercase;
    line-height: 10vh;
    padding-bottom: 5vh;
}
.baner p{
    color: var(--primary-text-color);
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2rem;
    padding-bottom: 2vh;
}

.baner img{
    height: 60vh;
    width: 100vw;
    /* filter: blur(4px); */
}

.baner-background{
/* stavi da iza teksta bude traka crne booje sa opacity: 0.5; */
    position: absolute;
    justify-content: center;
    width: 100%;
    height: 25%;
    background: #142D4C;
    opacity: 0.5;
}


.baner-path-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.baner-path-top svg {
    position: relative;
    display: block;
    width: calc(161% + 1.3px);
    height: 52px;
}

.baner-path-top .shape-fill {
    fill: var(--secondary-color);
}

.baner-path-top path[opacity=".25"] {
    fill: var(--third-color);
}

.baner-path-top path[opacity=".5"] {
    fill: var(--accent-color);
}

.baner-path-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.baner-path-bottom svg {
    position: relative;
    display: block;
    width: calc(109% + 1.3px);
    height: 39px;
}

.baner-path-bottom .shape-fill {
    fill: var(--secondary-color);
}

.baner-path-bottom path[opacity=".25"] {
    fill: var(--third-color);
}

.baner-path-bottom path[opacity=".5"] {
    fill: var(--accent-color);
}

.description-section{
    padding-top: 10vh;
    padding-bottom: 10vh;

}
/* RIGHT SECTION */

.right-section{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10vh;

}

.right-section-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    align-items: end;
    margin-left: 9vw;
    margin-right: 4vw;
}

.right-section-image{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 60% 80%;
    width: 30vw;
    height: 450px;
    border-radius: 10px;
    align-self: center;
    margin-left: 4vw;
    box-shadow: 0px 0px 10px 0px var(--button-color);

}

/* LEFT SECTION */

.left-section{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10vh;
}

.left-section-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    align-items: start;
    margin-left: 4vw;
    margin-right: 9vw;
}

.left-section-image{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 60% 80%;
    width: 30vw;
    height: 450px;
    border-radius: 10px;
    align-self: center;
    float:right;
    margin-right: 4vw;


}

.left-section-image img{
    /* filter: blur(2px); */
    width: 40vw;
    height: 550px;
}


/* LEFT LANDING */

.landing-left-section{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.landing-left-section-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    align-items: end;
    /* margin-left: 2vw; */
    margin-right: 9vw;
}
.landing-left-section-heading h1{
    background-image: url(img/clouds.jpeg); 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 60% 80%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.landing-left-section-image{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 60% 80%;
    width: 35vw;
    height: 450px;
    border-radius: 10px;
    align-self: center;
    margin-left: 4vw;

    /* overflow: hidden; */

}


/* RIGHT LANDING */

.landing-right-section{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.landing-right-section-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    align-items: start;
    margin-left: 9vw;
    margin-right: 2vw;
}

.landing-right-section-heading h1{
    background-image: url(img/clouds.jpeg); 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 60% 80%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.landing-right-section-image{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 60% 80%;
    width: 35vw;
    height: 450px;
    border-radius: 10px;
    align-self: center;
    margin-left: 4vw;
}
.landing-right-section-gallery{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 8vh;
    padding-left: 5vw;
    padding-right: 5vw;
    width: 550px;
    height: 750px;
    border-radius: 10%;
    /* blur iamge from top and bootom */
    /* filter: blur(2px); */


}

.landing-right-section-image img{
    width: 450px;
    height: 650px;
}

.landing-right-section-image img{
    /* stavi senku na sliku */

}

.box-shadow{
    box-shadow: 0 1px 2px rgba(0,0,0,0.17), 
                0 2px 4px rgba(0,0,0,0.17), 
                0 4px 8px rgba(0,0,0,0.17), 
                0 8px 16px rgba(0,0,0,0.17),
                0 16px 32px rgba(0,0,0,0.17), 
                0 32px 64px rgba(0,0,0,0.17);
}

.box-radius{
    border-radius: 2%;
}
/* =============== */
/* ================ */





/* 
section-image

baner-size
baner-h1
footer-h1
footer-p */

/* Stil za naslove sa pozadinskom slikom */
.background-clip-text {
    background-image: url(img/clouds.jpeg);
    background-size: cover;
    background-position: center;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    text-transform: uppercase;
}

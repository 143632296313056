@charset "utf-8";
/* CSS Document */

/* FOOTER */

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap'); */




.container{
	max-width: 1170px;
	margin: auto;
}
.row{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.footer{
    background: var(--accent-color);  /* Malo tamnija plava iz naše palete */
    padding: 30px 0 10px 0; /* Smanjeni padding */
    font-family: 'Montserrat', sans-serif;
    position: relative;
}
.footer-col{
   width: 22%;
   padding: 0 15px;
}
.footer-col h2{
	font-size: 1.2rem;
	color: var(--primary-text-color);  /* Svetlija plava za naslove */
	text-transform: uppercase;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
}
.footer-col h2::before{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: #5585b5;
	height: 2px;
	box-sizing: border-box;
	width: 70px;
}
.footer-col ul{
	list-style: none;
}
.footer-col ul li {
    font-size: 1rem;
    text-decoration: none;
    color: #B8C7D9;  /* Svetlija, više vidljiva nijansa za tekst */
    display: block;
    transition: all 0.3s ease;
    margin: 7px 0;
}
.footer-col ul li:hover {
    color: var(--primary-text-color);
    padding-left: 8px;
}


/*responsive*/
@media(max-width: 767px){
    .footer {
        padding: 20px 0 8px 0;
    }
    
    .footer-col {
        width: 50%;
        margin-bottom: 20px; /* Smanjeno sa 30px */
    }
    
    #rights {
        margin-top: 10px;
        padding: 6px 0;
        font-size: 0.8rem;
    }
}
@media(max-width: 574px){
  .footer-col{
    width: 100%;
}
	
	#rights{
		font-size: 0.8rem;
		margin-top: 10px;
		padding-top: 8px;
	}
}

/* Copyright tekst u footer-u */
#rights {
    color: #B8C7D9;
    border-top: 1px solid rgba(255,255,255,0.1);
    margin-top: 15px;
    padding: 8px 0;
    font-size: 0.85rem;
    text-align: center;
}

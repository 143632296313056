@media screen and (max-width: 1200px) {
    #desktop-nav {
        display: none;
    }

    #hamburger-nav {
        display: flex;
        padding-right: 10vw;
    }

    h2{

    }

    p{

    }
    
    .landing{
        /* padding-top: 20vh; */
        /* padding-bottom: 10vh; */
        display: flex;
        flex-direction: column;
        min-height: 0vh;
    }

    .landing-h1{
        font-size: 5rem;
    }

    .landing p{
        font-size: 1.25rem;
        width: 80vw;
    }

    .heading .line{
        width: 80vw;
    }


    .landing-image{
        display: none;
    }

    .baner{
        display: flex;
        flex-direction: column;
    }

    .baner h1{
        font-weight: 900;
        font-size: 7vw;
        line-height: 8vh;
    }

    .right-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .right-section-heading{
        align-items: center;
        margin-left: 0;
        margin-right: 0;
        
    }
    .right-section-heading .line{
        width: 30vw;
    }


    .right-section h2{
        
        text-align: center;
    }

    .right-section p{
        text-align: center;
    }

    .right-section-image, .left-section-image{
        display: none;
    }

    /*  */

    .left-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 5vw;
        padding-right: 5vw;

    }

    .left-section div:first-child{
        order: 1;
    }

    .left-section-heading{
        align-items: center;
        margin-left: 0;
        margin-right: 0;
        
    }
    .left-section-heading line{
        width: 30vw;
    }
    .left-section h2{
        text-align: center;
    }

    .left-section p{
        text-align: center;
    }

    .left-section img{
        height: 60vh;
        width: 50vh;
    }

    .footer {
        padding: 3vh 6vw 0;
    }

    .footer .container{
        

        justify-content: center;
        justify-self: center;
    }

    .row{
        flex-direction: column;
        align-items: center;
    }

    .footer-col{
        text-align: center;
        align-items: center;
        margin-top: 20px;
    }

    .footer-col h2{
        margin-bottom: 20px;
    }

    .footer-col h2::before{
        display: none;
    }
    

    .landing-left-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 10vh;
        padding-top: 7vh;
        min-height: 70vh;
    }

    .landing-left-section div:first-child{
        order: 1;
    }

    .landing-left-section-heading{
        
        align-items: start;
        
    }

    .landing-left-section h2{
        text-align: start;
    }
    .landing-left-section p{
        text-align: start;
    }

    .landing-left-section-image{
        display: none;
    }

    .about-us{
        padding-left: 10vw;
        padding-top: 5vh;
        padding-bottom: 5vh;
    }

    .landing-right-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 10vw;
        min-height: 75vh;
    }

    .landing-right-section-heading{
        align-items: left;   
        margin-left: 0;
    }

    .landing-right-section h2{
        text-align: left;
    }

    .landing-right-section p{
        text-align: left;
    }

    .landing-right-section-image{
        display: none;
    }

    .services{
        /* margin-top: 20vh; */
        /* margin-bottom: 10vh; */
        padding-top: 5vh;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .services-heading{
        padding-bottom: 4vh;
    }

    .services p{
        text-align: left;
    }

    .services-list-container{

        display: block;
        align-items: start;
        justify-content: start;

    }

    .services-list{
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        padding-bottom: 5vh;
    }

    .landing-right-section-gallery{
        display: none;
    }

    .project-header h2{
        font-size: 2rem;
    }

    .project-header p{
        font-size: 1rem;
    }
    .project-container {
        /* background-image: url(../img/project1.jpeg); */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 30vh;
        margin-bottom: 15vh;
        border: 15px solid var(--button-color);
    } 

    .project-info{
        /* fit to parent */
        width: 25vw;

    }
    .project-left{
        align-self: end;
    }
    .project-info h1{
        font-size: 1rem;
        padding: 10px;
    }

    .project-info p{
        padding: 10px;
        font-size: 1rem;
    }

    .project-heading{
        padding-bottom: 6vh;
        background-color: none;
    }
    
    .gallery-header h2 {
        font-size: 2rem;
        width: 70vw;
    }
    .gallery-row {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: none;
        /* padding-top: 10vh;  */
        padding-bottom: 8vh;
    }

    .contact-form {
        padding: 5vh 10vw;
    }
    .contact-info{
        display: flex;
        flex-direction: column;
        padding-top: 10vh;
    }
    .contact-form h2 {
        font-size: 2.5rem;
    }
    .contact-info h1{
        font-size: 25px;
    
    }

    .contact-info-right, .contact-info-left{
        width: 80vw;
        margin-bottom: 5vh;
        
    }
}

@media screen and (max-width: 600px) {
    #desktop-nav {
        display: none;
    }

    #hamburger-nav {
        display: flex;
        padding-right: 10vw;
    }

    .h1{
        font-size: 2rem;
    }
    h2{
        font-size: 2rem;
    }

    p{
        font-size: 0.9rem;
    }
    
    .landing{
        /* padding-top: 20vh; */
        /* padding-bottom: 10vh; */
        display: flex;
        flex-direction: column;
        min-height: 0vh;
        padding-right: 5vw;
    }

    .landing-h1{
        font-size: 3rem;
        line-height: 7vh;
    }

    .landing p{
        font-size: 1rem;
        width: 80vw;
    }

    .heading .line{
        width: 80vw;
        padding-right: 0;
    }
    .conntact-button{
        font-size: 1rem;
    }


    .landing-image{
        display: none;
    }
    .first-baner{
        padding-top: 0;
    }
    .baner{
        min-height: 50vh;
        display: flex;
        flex-direction: column;
    }
    
    .baner p{
        font-size: 1rem;
    }
    .baner h1{
        font-weight: 900;
        font-size: 2rem;
        line-height: 8vh;
    }

    .right-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 5vw;
        padding-right: 5vw;
        margin-bottom: 5vh;
    }

    .right-section-heading{
        align-items: center;
        margin-left: 0;
        margin-right: 0;
        
    }
    .right-section-heading .line{
        width: 30vw;
    }


    .right-section h2{
        
        text-align: center;
    }

    .right-section p{
        text-align: center;
    }

    .right-section-image, .left-section-image{
        display: none;
    }

    /*  */
    .description-section{
        padding-bottom: 5vh;
    }
    .left-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 5vw;
        padding-right: 5vw;
        margin-bottom: 5vh;
    }

    .left-section div:first-child{
        order: 1;
    }

    .left-section-heading{
        align-items: center;
        margin-left: 0;
        margin-right: 0;
        
    }
    .left-section-heading line{
        width: 30vw;
    }
    .left-section h2{
        text-align: center;
    }

    .left-section p{
        text-align: center;
    }

    .left-section img{
        height: 60vh;
        width: 50vh;
    }

    .footer {
        padding: 3vh 6vw 0;
    }

    .footer .container{
        

        justify-content: center;
        justify-self: center;
    }

    .row{
        flex-direction: column;
        align-items: center;
        flex-wrap: none;
    }

    .footer-col{
        text-align: center;
        align-items: center;
        margin-top: 20px;
    }

    .footer-col h2{
        margin-bottom: 20px;
    }

    .footer-col h2::before{
        display: none;
    }

    #rights{
        font-size: 1rem;
    }
    

    .landing-left-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 10vh;
        padding-top: 7vh;
        min-height: 70vh;
    }

    .landing-left-section div:first-child{
        order: 1;
    }

    .landing-left-section-heading{
        
        align-items: start;
        
    }

    .landing-left-section h2{
        text-align: start;
    }
    .landing-left-section p{
        text-align: start;
    }

    .landing-left-section-image{
        display: none;
    }

    .about-us{
        padding-left: 15vw;
        padding-top: 5vh;
        padding-bottom: 5vh;
    }

    .about-us h2{
        font-size: 2rem;
    }

    .landing-right-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 15vw;
        min-height: 75vh;
    }

    .landing-right-section-heading{
        align-items: left;   
        margin-left: 0;
        margin-right: 9vw;
    }

    .landing-right-section h2{
        text-align: left;
    }

    .landing-right-section p{
        text-align: left;
    }

    .landing-right-section-image{
        display: none;
    }

    .services{
        /* margin-top: 20vh; */
        /* margin-bottom: 10vh; */
        padding-top: 5vh;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .services-heading{
        padding-bottom: 4vh;
    }

    .services p{
        text-align: left;
    }

    .services-list-container{

        display: block;
        align-items: start;
        justify-content: start;

    }

    .services-list{
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        padding-bottom: 5vh;
    }

    .services-list h2{
        font-size: 2rem;
    }

    .services-list ul{
        font-size: 0.9rem;
    }

    .landing-right-section-gallery{
        display: none;
    }

    .projects{
        padding-bottom: 0;
    }
    .projects-landing-section{
        padding-top: 15vh;
        min-height: 60vh;
    }
    .project-header-section{
        min-height: 30vh;
    }
    .project-header{
        padding: 2px;
        width: 80vw;
    }
    .project-header h2{
        font-size: 1rem;
    }

    .project-header p{
        width: 65vw;
        font-size: 0.8rem;
    }
    .project-container {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 30vh;
        margin-bottom: 8vh;
        border: none;
        /* border: 15px solid var(--button-color); */
    } 

    .project-info{
        /* fit to parent */
        width: 25vw;
        display: none;

    }
    .project-left{
        align-self: end;
    }
    .project-info h1{
        font-size: 1rem;
        padding: 10px;
    }

    .project-info p{
        padding: 10px;
        font-size: 1rem;
    }

    .project-heading{
        font-size: 20px;
        padding-bottom: 6vh;
        background-color: none;
    }
    
    .gallery-header h2 {
        font-size: 1.25rem;
        width: 70vw;
    }
    .gallery-header p{
        font-size: 0.8rem;
        text-align: center;
    }
    .gallery-row {
        padding-top: 0;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: none;
        /* padding-top: 10vh;  */
        padding-bottom: 8vh;
    }
    .gallery-item{
        height: 200px;
    }

    .contact-form {
        padding: 5vh 10vw;
    }
    .contact-info{
        display: flex;
        flex-direction: column;
        padding-top: 10vh;
    }
    .contact-form h2 {
        font-size: 2rem;
    }

    .contact-form p{
        width: 75vw;
    }
    .contact-info h1{
        font-size: 25px;
    
    }

    .input-row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 3vh;
    }
    .row-item{
        height: 18px;
        align-self: center;
        width: 70vw;

    }
    .button-container button{
        margin:10px 0;
    }
    .contact-info-right, .contact-info-left{
        align-self: center;
        width: 70vw;
        margin-bottom: 5vh;
        
    }
    .contact-info-left{
        height: 320px;
    }
    .contact-info-left h1{
        font-size: 20px;
    }
    .contact-info-left h2{
        font-size: 15px;
    }
    .contact-info-left p{
        font-size: 14px;
    }

}
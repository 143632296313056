
.services{
  padding-top: 10vh;
  padding-bottom: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.services-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    align-items: left;
    margin-left: 10vw;
    margin-right: 10vw;
}

.services-heading h2{
    text-transform: uppercase;
}

.services-heading p{

    margin-top: 3vh;
    /* line-height: 1.5; */
    /* max-width: 55ch; */
    /* font-size: 1.25rem; */
    margin-right: 15vw;

    
}
.services-list-container{
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    /* align-items: center; */
    margin-top: 5vh;
    margin-bottom: 5vh;
    margin-left: 9vw;
    color: white;

}

.services-list{
    margin-left: 2vw;
    margin-right: 2vw;
}


.services-list h2{
    font-size: 2.5rem;
    text-transform: uppercase;
}

.services-list ul{
    /* put dots in list */
    list-style-type: disc;
    margin-top: 3vh;
    line-height: 1.5;
    max-width: 55ch;
    font-size: 1.25rem;

}

    /* napraviti animaciju da se cela .about-us-heading sekcija pojavi sa 1 sekundom zakasnjena od trenutka kad se fokusira na nju */
    .services {
        opacity: 1; /* Postavljamo početnu transparentnost na 0 */
        transition: opacity 1s ease; /* Dodajemo prelazak za promenu transparentnosti */
        transition-delay: 0.5s;

      }
      
      .services.visible {
        opacity: 1; /* Postavljamo transparentnost na 1 kada je vidljivo */
      }


    @keyframes slideInLeft {
        from {
          transform: translateX(-20%);
          opacity: 0.5;
        }
        to {
          transform: translateX(0);
          opacity: 1;
        }
      }
      
      .landing-right-section-heading h1{
        animation: slideInRight 1s ease forwards;
      }
      .landing-right-section-heading h2{
        animation: slideInRight 1.5s ease forwards;
      }
    
      .landing-right-section-heading p {
        animation: slideInRight 2s ease forwards;
      }

@keyframes slideInRight {
    from {
      transform: translateX(20%);
      opacity: 0.5;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
} 

.landing-right-section-image img {
    animation: slideInLeft 1s ease forwards;
}


/* TRANSITION */

 .navigation{ 
    text-transform: uppercase;
    position: fixed;
    z-index: 1000;
    width: 100%;
    background-color: var(--third-color);
    font-size: 30px;
    font-weight: 700;
 }
/* .logo img{
    width: 200px;
    height: 150px;
    margin-top: 10px;
    margin-left: 10px;
} */
/* DESKTOP NAV */
nav, .nav-links{
    display : flex;
}

nav {
    justify-content: space-between;
    align-items: center;
    height: 10vh;
}

.nav-links {
    padding-right: 6vw;
    gap: 2rem;
    font-size: 1.25rem;    
}

.nav-link {
    text-decoration: none;
    /* transition: color 0.3s ease; */
    color: var(--primary-text-color)

}

.nav-link:hover {
    color: var(--hover-color);
}

.logo {
    padding-left: 6vw;
    text-decoration: none;
    font-size: 2rem;
    color: var(--primary-text-color);
}

.logo:hover {
    color: var(--hover-color);
}

/* HAMBURGER MENU */

#hamburger-nav {
    display: none;

}

.hamburger-menu {
    position: relative;
    display: inline-block;

}

.hamburger-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 24px;
    width: 30px;
    cursor: pointer;
}

.hamburger-icon span{
    width: 100%;
    height: 2px;
    background-color: #5585b5;
    transition: all 0.3 ease-in-out;

}

.menu-links{


    position: fixed;
    background: var(--button-color);
    flex-direction: column;
    min-width: 100%;
    height: 100vh;
    top: 0;
    right: -130%;
    padding-top: 20vh;
    /* padding: 80px 50px; */
    transition: 0.5s;
    transition-property: right;
}

.menu-link{
    display: block;
    padding: 25px;
    text-align: center;
    text-decoration: none;
    font-size: 1.25rem;
    transition: all 0.3 ease-in-out;
    color: #5585b5;

}

.hamburger-menu.open .menu-links {
    right: 0;
  
}
.hamburger-icon.open span:first-child{
    transform: rotate(45deg) translate(10px, 5px);
}

.hamburger-icon.open span:nth-child(2){
    opacity: 0;
}

.hamburger-icon.open span:last-child{
    transform: rotate(-45deg) translate(10px, -5px);
}

.hamburger-icon span:first-child{
    transform: none;
}

.hamburger-icon span:first-child{
    opacity: 1;
}

.hamburger-icon span:first-child{
transform: none;
}

@media screen and (max-width: 1200px) {
    #desktop-nav {
        display: none;
    }

    #hamburger-nav {
        display: flex;
    }
}
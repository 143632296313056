.contact-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: 2rem 8rem;
}

.contact-info-left, .contact-info-right {
    min-height: 450px;  /* Smanjeno sa 500px */
    height: 100%;      
    padding: 1.5rem;    /* Smanjeno sa 2rem */
}

.contact-info-left {
    background: var(--third-color);
    border-radius: 10px;
    overflow: hidden;   /* Sprečava da sadržaj izađe van */
    display: flex;
    flex-direction: column;
}

.contact-info-left h1 {
    margin-bottom: 2rem;
}

.data {
    display: flex;
    flex-direction: column;
    gap: 1rem;         /* Razmak između podataka */
    overflow-y: auto;  /* Dodaje scroll ako sadržaj prelazi visinu */
}

.data h2 {
    font-size: 1.5rem;
    margin: 0.5rem 0;
}

.data p {
    margin: 0;
}

.contact-info-right {
    border-radius: 10px;
    overflow: hidden;
}

.contact-info-right iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
}

.contact-form {
    padding: 5vh 20vw;
    min-height: 80vh;
}
.contact-form-header {
    padding-top: 10vh;
    padding-bottom: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

}
.contact-form h2 {
    text-align: center;
}

.contact-form p {
    width: 50vw;
}

.fill{
    color: var(--primary-text-color);
}

.form-group {
    display: flex;
    flex-direction: column;
    padding-bottom: 3vh;
}

.input-row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 3vh;
    /* posto input row ima 2 elementa stavi da da  */

}

.row-item{
    width: 45%;
    height: 3vh;

}

.form-group input,
.form-group textarea {
    padding: 15px;
    border-color: var(--primary-text-color);
    border-radius: 5px;
    background-color: var(--button-color);
    color: var(--third-text-color);

}

.message {
    height: 15vh;
    resize: none;
}


button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--primary-text-color);
}

.button-container{
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5vh;
    
}
.button-container p{
    font-size: 0.8rem;
    opacity: 60%;
    margin: 0;
}

/* button:hover {
    background-color: var(--button-hover-color);
} */

/* Responsive dizajn */
@media (max-width: 768px) {
    .contact-info {
        padding: 2rem;  /* Manji padding na mobilnom */
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .contact-info-right {
        min-height: 350px;  /* Smanjeno sa 400px */
        order: -1;
    }

    .contact-info-left {
        min-height: 350px;  /* Smanjeno sa 400px */
    }
}

.projects-landing-section{
    max-height: 50vh;
}

.landing-right-section-gallery{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    padding-top: 8vh;
    padding-left: 5vw;
    padding-right: 5vw;
    width: 40vw;
    height: 50vh;
    border-radius: 10%;
    opacity: 50%;
}
.landing-right-section-gallery img{
    padding: 50px;
    border-radius: 20px;
    /* background-color: var(--button-color); */
}
.projects{
    /* stavi pading top i bottom po 15vh */
    padding-top: 10vh;
    padding-bottom: 10vh;
}
.project-header-section{
    /* height: 50vh; */
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    /* justify-content: center; */
    align-items: center;
}
.project-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--button-color);
    border-radius: 20px;    
    padding: 5vh 5vw;
}
.project-header p{
    text-align: center;
    width: 50vw;
}
.projects{
    padding-right: 10vw;
    padding-left: 10vw;
}
.landing-right-section-heading .line{
    opacity: 0;
    animation: sladeInLeft 2s ease forwards; /* Dodajemo animaciju */
    animation-delay: 0.8s;

}

.landing-right-section-heading .button-container{
padding-top: 2vh;
opacity: 0;
animation: sladeInLeft 2s ease forwards; /* Dodajemo animaciju */
animation-delay: 1s;
}

.project-container{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 50vh;
    margin-bottom: 15vh;
    border-radius: 10px;
    border: 25px solid var(--button-color);
    /* napravi jacu senku */
    box-shadow: 0px 0px 10px 0px var(--button-color);

}

.project-left{
    display: grid;
    grid-template-columns: 70% 30%;
    margin-bottom: 15vh;
}

.project-right{
    display: grid;
    grid-template-columns: 30% 70%;
    margin-bottom: 15vh;
}

.project-heading{
    align-self: center;
    text-transform: uppercase;
    font-size: 3vw;
    color: var(--third-text-color);
    padding-right: 2vw;
    padding-left: 5vw;
    font-weight: bold;
    letter-spacing: 5px; 
}

/*OPIS PROJEKTA*/
.project-info{
    display: grid;
    background-color: var(--button-color);
    font-size: 0.9vw;
    font-weight: 400;
    /* height: 100%; */
}

.project-info *{
    /* text-align: center; */
    margin-left: 1vw;
    margin-right: 1vw;
    /* letter-spacing: 2px; */
}

.project-info h1{
    align-self: end;
    font-size: 1.7rem;
}
.right-info{
    text-align: right;
}   

/* GALLERY */
.gallery-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5vw;
    /* padding-left: 5vw; */
    /* padding-right: 5vw; */
    padding-top: 10vh;
    padding-bottom: 10vh;
}
.gallery-item{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 50vh;
    border-radius: 10px;
    /* border: 25px solid var(--button-color); */
}

.gallery-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;    
    padding: 5vh 5vw;
}

.gallery-header h2{
    text-align: center;
    width:50vw
}

